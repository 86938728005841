import './printing-test-result.css'

import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ScoreRadarChartPrinting, {
  ScoreData,
} from '~/components/graph/ScoreRadarChartPrinting'
import { getShowingTestResult } from '~/school-utils/result'
import type { ITeacherSchool } from '~/types/api/alpha/teachers'
import {
  ageFromSchool,
  reCalcTestResult,
  schoolHasEnduranceTest,
} from '~/utils'
import { showBmi } from '~/utils/bmi'
import { currentYear } from '~/utils/constants'
import { getComment } from '~/utils/fitnessTest'
import isJapanese from '~/utils/isJapanese'
import { TEachTestRes } from '~/utils/points'
import { getPrefectureAverage } from '~/utils/prefecture-average/average'
import { IPrefectureAverage } from '~/utils/prefecture-average/prefectureCodes'
import {
  TAllTestKey,
  testKeysHasEnduranceRunOnly,
  testKeysHasShuttleRunOnly,
  testsTitleAndUnit,
} from '~/utils/test-const'
import type { Question } from '~/utils/types'
import { IAverageValue } from '../../../../../utils/types/api/res'
import { StudentTestResult } from '../../types/test-result'
import QuestionsTable from './ResultDetail/QuestionsTable'
import StatisticHeader from './ResultDetail/StatisticHeader'
import StatisticValueTable from './ResultDetail/StatisticValueTable'
import SummaryPointAndRank from './ResultDetail/SummaryPointAndRank'

type Props = {
  school: ITeacherSchool
  testYear: number
  student: StudentTestResult
  questionData: Question[]
  hasEnduranceRun: boolean
  questionFilledEmptyCellsLength: number
}

/**
 * For modal only.
 */
const TestResultDetail = ({
  school,
  testYear,
  student,
  questionData,
  hasEnduranceRun,
  questionFilledEmptyCellsLength,
}: Props) => {
  const { t, i18n } = useTranslation()
  const isUsingJp = isJapanese(i18n)

  const [dataAverage, setDataAverage] = useState<IAverageValue | null>(null)

  const prevTestResult = student.lastYearResults || null

  const age = ageFromSchool(
    school.attributes.schoolCategoryCode,
    student.attributes.schoolGrade,
  )

  const gender = student.attributes.gender

  const testResult = reCalcTestResult(
    student.attributes.testResults?.find((item) => item.testYear === testYear),
    age,
    gender,
    hasEnduranceRun,
  )

  const shuttleRunPoint = testResult.shuttleRun?.points
  const enduranceRunPoint = testResult.enduranceRun?.points

  const isShowEnduranceRunOnly = hasEnduranceRun
    ? (enduranceRunPoint ?? 0) > (shuttleRunPoint ?? 0)
    : false

  const scoreData: ScoreData[] = isShowEnduranceRunOnly
    ? [
        {
          subject: t('握力'),
          personal: testResult.grip?.points ?? 0,
          prevPersonal: prevTestResult?.gripValue ?? 0,
        },
        {
          subject: t('上体起こし'),
          personal: testResult.sitUps?.points ?? 0,
          prevPersonal: prevTestResult?.sitUpsValue ?? 0,
        },
        {
          subject: t('長座体前屈'),
          personal: testResult.bending?.points ?? 0,
          prevPersonal: prevTestResult?.bendingValue ?? 0,
        },
        // running type
        {
          subject: t('持久走'),
          personal: enduranceRunPoint ?? 0,
          prevPersonal: prevTestResult?.enduranceRunValue ?? 0,
        },

        {
          subject: t('50m走'),
          personal: testResult.sprintRun?.points ?? 0,
          prevPersonal: prevTestResult?.sprintRunValue ?? 0,
        },
        {
          subject: t('反復横跳び'),
          personal: testResult.sideJump?.points ?? 0,
          prevPersonal: prevTestResult?.sideJumpValue ?? 0,
        },
        {
          subject: t('立ち幅とび_break'),
          personal: testResult.standingJump?.points ?? 0,
          prevPersonal: prevTestResult?.standingJumpValue ?? 0,
        },
        {
          subject: t('ボール投げ'),
          personal: testResult.handballThrow?.points ?? 0,
          prevPersonal: prevTestResult?.handballThrowValue ?? 0,
        },
      ]
    : [
        {
          subject: t('握力'),
          personal: testResult.grip?.points ?? 0,
          prevPersonal: prevTestResult?.gripValue ?? 0,
        },
        {
          subject: t('上体起こし'),
          personal: testResult.sitUps?.points ?? 0,
          prevPersonal: prevTestResult?.sitUpsValue ?? 0,
        },
        {
          subject: t('長座体前屈'),
          personal: testResult.bending?.points ?? 0,
          prevPersonal: prevTestResult?.bendingValue ?? 0,
        },
        // running type
        {
          subject: t('シャトルラン'),
          personal: shuttleRunPoint ?? 0,
          prevPersonal: prevTestResult?.shuttleRunValue ?? 0,
        },
        {
          subject: t('50m走'),
          personal: testResult.sprintRun?.points ?? 0,
          prevPersonal: prevTestResult?.sprintRunValue ?? 0,
        },
        {
          subject: t('反復横跳び'),
          personal: testResult.sideJump?.points ?? 0,
          prevPersonal: prevTestResult?.sideJumpValue ?? 0,
        },
        {
          subject: t('立ち幅とび_break'),
          personal: testResult.standingJump?.points ?? 0,
          prevPersonal: prevTestResult?.standingJumpValue ?? 0,
        },
        {
          subject: t('ボール投げ'),
          personal: testResult.handballThrow?.points ?? 0,
          prevPersonal: prevTestResult?.handballThrowValue ?? 0,
        },
      ]

  const testKeys = isShowEnduranceRunOnly
    ? testKeysHasEnduranceRunOnly
    : testKeysHasShuttleRunOnly

  const [listAnswers, setListAnswers] = useState<
    Record<string, string | number> | undefined
  >(undefined)

  /**
   * 県平均
   */
  const [prefectureAverage, setPrefectureAverage] = useState<
    IPrefectureAverage | false
  >(false)

  useEffect(() => {
    const dataAnswer = {}
    if (student.attributes.testResults?.length) {
      if (student.attributes.testResults[0].questionnaireV3) {
        for (const [key, value] of Object.entries(
          student.attributes.testResults[0].questionnaireV3,
        )) {
          dataAnswer[key.replace('q', '')] = value
        }
      }
    }
    setListAnswers(dataAnswer)

    setPrefectureAverage(
      getPrefectureAverage(
        school.attributes.prefectureCode,
        gender,
        student.attributes.schoolGrade,
        school.attributes.schoolCategoryCode,
      ),
    )
  }, [
    gender,
    school.attributes.prefectureCode,
    school.attributes.schoolCategoryCode,
    student.attributes.schoolGrade,
    student.attributes.testResults,
  ])

  const isEnduranceRun = schoolHasEnduranceTest(school.attributes)

  useEffect(() => {
    getDataListAverage()
  }, [gender, student.attributes.schoolGrade])

  const getDataListAverage = async () => {
    const apiNationalAverage: IAverageValue = {}

    student.attributes.listAveragePoints?.forEach((averagePoint) => {
      switch (averagePoint.key) {
        case 'GRIP_STRENGTH':
          apiNationalAverage.grip = averagePoint.value
          break
        case 'BENDING':
          apiNationalAverage.bending = averagePoint.value
          break
        case 'SIT_UPS':
          apiNationalAverage.sitUps = averagePoint.value
          break
        case 'SIDE_JUMP':
          apiNationalAverage.sideJump = averagePoint.value
          break
        case 'SHUTTLE_RUN':
          apiNationalAverage.shuttleRun = averagePoint.value
          break
        case 'SPRINT_RUN':
          apiNationalAverage.sprintRun = averagePoint.value
          break
        case 'STANDING_JUMP':
          apiNationalAverage.standingJump = averagePoint.value
          break
        case 'HANDBALL_THROW':
          apiNationalAverage.handballThrow = averagePoint.value
          break
        case 'ENDURANCE_RUN':
          apiNationalAverage.enduranceRun = averagePoint.value
          break
      }
    })

    setDataAverage(apiNationalAverage)
  }

  return (
    <div className="p-2 test-result-detail-container">
      <Row className="text-center">
        <div className="result-title-container">
          {t('新体力テスト個人結果表')}
        </div>

        <div className="result-info-container">
          <Row className="year-school-name-logo-container">
            <span className="background-input round-border year-container flex items-center">
              <span className="year-val font-bold">{currentYear}</span>
              <span>{t('年度')}</span>
            </span>

            <span className="school-name-txt flex items-center">
              {t('学校名')}
            </span>
            <span className="background-input round-border school-name flex items-center justify-center flex-grow font-bold">
              {student.attributes.schoolName}
            </span>

            {/* Logo */}
            <img
              alt=""
              src="printing-result-logo.png"
              width={'20%'}
              className="pl-2 w-1/5 ml-auto"
            />
          </Row>

          <Row className="student-info-container">
            <span className="student-info-val font-bold">
              {student.attributes.schoolGrade}
            </span>
            <span>{t('grade年')}</span>

            <span className="student-info-val font-bold">
              {student.attributes.schoolClass}
            </span>
            <span>{t('組')}</span>

            <span className="student-info-val font-bold">
              {student.attributes.schoolAttendanceNumber}
            </span>
            <span>{t('番')}</span>

            <span className="student-info-name-txt">{t('名前')}</span>
            <span className="font-bold">
              {`${student.attributes.familyName ?? ''} ${
                student.attributes.givenName ?? ''
              }`}
            </span>
          </Row>
        </div>
      </Row>
      {/* end of header container */}

      {/* bmi */}
      <Row className="bmi-container text-black">
        <span className="bmi-txt">{t('身長')}</span>
        <span className="bmi-input background-input relative">
          <span className="bmi-val absolute font-bold">
            {testResult.sizeTest?.height != null
              ? `${testResult.sizeTest.height.toFixed(1)}`
              : '-'}
          </span>
          <span className="bmi-unit absolute">cm</span>
        </span>

        <span className="bmi-txt">{t('体重')}</span>
        <span className="bmi-input background-input relative">
          <span className="bmi-val absolute font-bold">
            {testResult.sizeTest?.weight != null
              ? `${testResult.sizeTest.weight.toFixed(1)}`
              : '-'}
          </span>
          <span className="bmi-unit absolute">kg</span>
        </span>

        <span className="bmi-txt">BMI</span>
        <span className="bmi-input background-input flex justify-center items-center">
          <span className="font-bold">{showBmi(testResult.sizeTest)}</span>
        </span>
      </Row>
      {/* end bmi */}

      <StatisticHeader
        prefectureAverage={prefectureAverage}
        t={t}
        isUsingJp={isUsingJp}
      />

      {/* statistic table */}
      <Row>
        <Col span={18}>
          {testKeys.map((key: TAllTestKey) => {
            const result: TEachTestRes | undefined = testResult[key]

            const points = result?.points || '-'

            const test = testsTitleAndUnit[key]
            const { title, suffix } = test

            const comment = result
              ? getComment(result.rating, age, key)
              : undefined

            return (
              <StatisticValueTable
                key={key}
                keyName={key}
                title={t(title)}
                value={getShowingTestResult(result)}
                result={result}
                suffix={t(suffix)}
                points={points}
                nationalAverage={dataAverage}
                comment={comment}
                prefectureAverage={prefectureAverage}
                t={t}
                isUsingJp={isUsingJp}
              />
            )
          })}
        </Col>

        <Col span={6}>
          <ScoreRadarChartPrinting data={scoreData} />

          <SummaryPointAndRank
            testResult={testResult}
            isEnduranceRun={isEnduranceRun}
            t={t}
            isUsingJp={isUsingJp}
          />
        </Col>
      </Row>

      <QuestionsTable
        answers={listAnswers}
        questionData={questionData}
        t={t}
        filledEmptyCellsLength={questionFilledEmptyCellsLength}
      />
    </div>
  )
}

export default TestResultDetail
