import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { UserGender } from '~/alpha/core'
import { TColumn } from '../types'
import { roundDecimal } from '~/utils/constants'

const roundValue = (v: number) => v ? roundDecimal(v, 2) : '0.0'

const ClassesReportTable = ({
  reportTestByClassSummary,
  gender,
  showType,
  loading,
  showPage,
  hasEnduranceTest,
}) => {
  const { t } = useTranslation()

  const isMale = gender === UserGender.Male
  const genderClass = isMale ? 'male' : 'female'
  const suffix = showType === 'average' ? 'avg' : 'std'
  const rowColor = isMale ? 'blue' : 'pink'

  const testColumns: TColumn[] = [
    {
      title: t('学年'),
      dataIndex: 'grade',
      key: 'grade',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
    },
    {
      title: t('クラス'),
      dataIndex: ['_id', 'schoolClass'],
      key: 'schoolClass',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
    },
    {
      title: t('人数'),
      dataIndex: 'count',
      key: 'count',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
    },
    {
      title: t('身長'),
      dataIndex: `height_${suffix}`,
      key: `height_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('体重'),
      dataIndex: `weight_${suffix}`,
      key: `weight_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('握力'),
      dataIndex: `grip_${suffix}`,
      key: `grip_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('上体起こし'),
      dataIndex: `sitUps_${suffix}`,
      key: `sitUps_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('長座体前屈'),
      dataIndex: `bending_${suffix}`,
      key: `bending_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('反復横跳び'),
      dataIndex: `sideJump_${suffix}`,
      key: `sideJump_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('シャトルラン'),
      dataIndex: `shuttleRun_${suffix}`,
      key: `shuttleRun_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => roundValue(v),
    },
    ...(hasEnduranceTest
      ? [
          {
            title: t('持久走'),
            dataIndex: `enduranceRun_${suffix}`,
            key: `enduranceRun_${suffix}`,
            className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
            render: (v, _) => roundValue(v),
          },
        ]
      : []),
    {
      title: t('50m走'),
      dataIndex: `sprintRun_${suffix}`,
      key: `sprintRun_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('立ち幅跳び'),
      dataIndex: `standingJump_${suffix}`,
      key: `standingJump_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('ボール投げ'),
      dataIndex: `handballThrow_${suffix}`,
      key: `handballThrow_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => roundValue(v),
    },
    {
      title: t('得点合計'),
      dataIndex: `points_${suffix}`,
      key: `points_${suffix}`,
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v, _) => roundValue(v),
    },
  ]
  return (
    <>
      <div className="space-y-2">
        <div
          className={`h-6 flex justify-center items-center text-xs text-white font-black table-${genderClass}-test`}
        >
          {t('クラス平均値')}
          {t(isMale ? '（男子）' : '（女子）')}
        </div>
        {showPage === 'print' ? (
          <Table
            columns={testColumns}
            dataSource={
              isMale
                ? reportTestByClassSummary?.male
                : reportTestByClassSummary?.female
            }
            style={{ width: 1185 }}
            size="small"
            className="print-component"
            rowClassName={(_, idx) => {
              const _default = 'text-10px text-black font-bold'
              return `${_default} ${
                idx % 2 !== 0
                  ? `ant-table-row-${rowColor}-light`
                  : `ant-table-row-${rowColor}-dark`
              }`
            }}
            loading={loading}
            pagination={false}
          />
        ) : (
          <Table
            columns={testColumns}
            dataSource={
              isMale
                ? reportTestByClassSummary?.male
                : reportTestByClassSummary?.female
            }
            style={{ width: 1185 }}
            size="small"
            className="print:hidden"
            rowClassName={(_, idx) => {
              const _default = 'text-10px text-black font-bold'
              return `${_default} ${
                idx % 2 !== 0
                  ? `ant-table-row-${rowColor}-light`
                  : `ant-table-row-${rowColor}-dark`
              }`
            }}
            loading={loading}
            pagination={{
              hideOnSinglePage: true,
              defaultPageSize: 10,
            }}
          />
        )}
      </div>
    </>
  )
}

export default ClassesReportTable
