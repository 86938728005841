import { Form, Select, message } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Api from '~/utils/api'
import { currentYear } from '~/utils/constants'

function getListData(start, end, state = true) {
  const list = []
  if (state) {
    for (let i = start; i <= end; i++) {
      list.push({ value: i.toString(), label: i.toString() })
    }
  } else {
    for (let i = end; i >= start; i--) {
      list.push({ value: i.toString(), label: i.toString() })
    }
  }
  return list
}

export const DateForm = ({ dataBirth, testYear }) => {
  const listYear = getListData(1970, currentYear, false)
  const listMonth = getListData(1, 12)
  const [listDay, setListDay] = useState(getListData(1, 31))
  const { t } = useTranslation()

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(dataBirth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBirth])

  const handleChange = async () => {
    const data = form.getFieldsValue()
    if (data?.yearBirth && data?.monthBirth) {
      const date = new Date(data?.yearBirth, data?.monthBirth, 0).getDate()
      setListDay(getListData(1, date))
      if (data?.dayBirth) {
        if (data?.dayBirth > date) {
          form.setFieldsValue({
            yearBirth: data?.yearBirth,
            monthBirth: data?.monthBirth,
            dayBirth: '',
          })
        }
      }
    }
    const submitData = form.getFieldsValue()
    const apiUrl = `/alpha/v1/school/editDateOfBirthStudents/${dataBirth?._id}?testYear=${testYear}`

    try {
      await Api.post(apiUrl, submitData)
      message.success(t('編集完了'))
    } catch (err) {
      console.error(`${apiUrl} API calling error:`, err)
    }
  }
  return (
    <Form form={form} layout="vertical" hideRequiredMark={true}>
      <div className="flex align-center items-center my-2">
        <Form.Item name="yearBirth">
          <Select
            style={{ width: 120 }}
            onChange={handleChange}
            options={listYear}
          />
        </Form.Item>
        <span className="mx-2 textDate flex items-center">年</span>
        <Form.Item name="monthBirth">
          <Select
            style={{ width: 80 }}
            onChange={handleChange}
            options={listMonth}
          />
        </Form.Item>
        <span className="mx-2 textDate flex items-center">月</span>
        <Form.Item name="dayBirth">
          <Select
            style={{ width: 80 }}
            onChange={handleChange}
            options={listDay}
          />
        </Form.Item>
        <span className="mx-2 textDate flex items-center">日</span>
      </div>
    </Form>
  )
}
