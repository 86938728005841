import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Button, Col, List, Row, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { fetchSchoolClasses } from '@alpha/core'
import { currentYear, pageSize as defaultPageSize } from '~/utils/constants'

import { useAlphaStore } from '~/context'
import {
  useSchoolGradesApiQuery,
  useSchoolSurveysApiQuery,
} from '~/hooks/hooks'
import { Surveys } from '~/types/api/survey'
import isJapanese from '~/utils/isJapanese'
import { getCurrentPrefectureQuestions } from '~/utils/questions'
import { allQuestions, getExtraQuestion } from '~/utils/questions/all-questions'
import {
  Question,
  StudentQuestionExtra,
  StudentQuestionExtraText,
} from '~/utils/types'
import { Dashboard } from '../layout/Dashboard'
import TableQuestion from '../layout/TableQuestion'

const { Option } = Select

/**
 * アンケート結果の入力ページ
 *
 * Path: /questionnaire
 */
const QuestionnaireInputPage = () => {
  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)

  const { school } = useAlphaStore()
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'
  const prefectureCode = school?.attributes?.prefectureCode

  const [schoolGrade, setSchoolGrade] = useState(0)
  const [schoolClass, setSchoolClass] = useState(0)
  const [schoolClasses, setSchoolClasses] = useState<number[]>([])
  const [data, setData] = useState<undefined | Surveys['data']>()
  const [questionData, setQuestionData] = useState<Question[]>([])
  const [isCollapsed, setCollapsed] = useState(false)
  const [listQuestion, setListQuestion] = useState<number[]>([])
  const [questionExtra, setQuestionExtra] = useState<StudentQuestionExtra>({})
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(defaultPageSize)

  const [total, setTotal] = useState(0)
  const { data: grades, loading: gradesLoading } = useSchoolGradesApiQuery()

  const { data: surveys, loading: surveysLoading } = useSchoolSurveysApiQuery({
    testYear: currentYear,
    schoolGrade,
    schoolClass,
    limit: pageSize,
    offset: (currentPage - 1) * pageSize,
  })

  useEffect(() => {
    setData(surveys?.data)
    setTotal(surveys?.total ?? 0)
  }, [surveys?.data, surveys?.total])

  useEffect(() => {
    if (school) {
      fetchSchoolClasses(school._id, currentYear).then((res) => {
        const schoolClasses = res.schoolClasses
        if (schoolClasses.length) {
          schoolClasses.sort()
          setSchoolClasses(schoolClasses)
        }
      })
    }
  }, [school])

  // Get questions
  useEffect(() => {
    if (prefectureCode === undefined || !school) return

    const questionIds = getCurrentPrefectureQuestions(
      prefectureCode,
      school._id,
      isElementarySchool,
    )

    if (questionIds.length === 0) return

    setListQuestion(questionIds)

    /**
     * To update this prefecture question list.
     */
    const prefectureQuestionObject: Record<string, Question> = {}

    for (const question of allQuestions) {
      if (questionIds.includes(question.id)) {
        // question is modified later, so copy question information to new object
        prefectureQuestionObject[question.id] = { ...question }
      }
      const extraQuestion = getExtraQuestion(
        school.attributes.schoolName,
        question,
      )
      if (question.id === 45 && extraQuestion) {
        setQuestionExtra(extraQuestion)
      }
    }

    /**
     * To reorder questions
     */
    let prefectureQuestionDetails: Question[] = []

    questionIds.forEach((id) => {
      if (!prefectureQuestionObject[id]) {
        console.error('not found question id:', id)
        throw new Error(
          'question ids and all questions are not matching! For example, question ID is 200, but in question list, there is no question with ID 200!',
        )
      }
      prefectureQuestionDetails.push(prefectureQuestionObject[id])
    })

    prefectureQuestionDetails = prefectureQuestionDetails.map(
      (value, index) => {
        value.question = `Q${index + 1} : ${value.question}`
        return value
      },
    )

    // customize questionnaire for elementary school of ibaraki prefecture
    if (
      prefectureCode === 8 &&
      isElementarySchool &&
      prefectureQuestionDetails?.length > 7
    ) {
      prefectureQuestionDetails[7].question =
        'Q8 : 1日にどのくらいテレビを見ますか（テレビゲームを含みます）'
    }

    setQuestionData(prefectureQuestionDetails)
  }, [school, isElementarySchool, prefectureCode])

  const renderQuestion = (questionData: Question, idx: number) => {
    if (!school?.attributes?.schoolName) return

    let answers: JSX.Element | undefined

    if (questionData.options) {
      answers = (
        <Row className="w-full" gutter={8}>
          {Object.keys(questionData.options).map((answer, idx) => {
            const value = questionData.options[answer]
            return (
              <Col key={idx}>
                <span className={isCollapsed ? 'hidden' : 'pl-10 block'}>
                  {value + (answer && `.${answer}`)}
                </span>
              </Col>
            )
          })}
        </Row>
      )
    }

    if (questionData.id === 45 && questionData.options) {
      const extra = getExtraQuestion(school.attributes.schoolName, questionData)

      if (extra) {
        const options: { value: string; name: string }[] = []
        const extraKeys = Object.keys(extra)

        for (const key of extraKeys) {
          const optVal = `${key}.${
            (extra[key] as StudentQuestionExtraText).name
          }`

          options.push({
            value: optVal,
            name: optVal,
          })
        }

        answers = (
          <Row className="w-full" gutter={8}>
            {<Select defaultValue={`1.${extra[1].name}`} options={options} />}
          </Row>
        )
      }
    }

    const showEllipsis = isCollapsed && idx === 0

    return (
      <List.Item
        style={{
          width: prefectureCode === 45 ? 860 : 800,
          borderStyle: 'none',
        }}
      >
        <>
          <div
            className={
              showEllipsis
                ? 'font-black collapsed-questions relative w-max '
                : 'font-black whitespace-pre-wrap'
            }
            {...{ ellipsis: '...' }}
          >
            {questionData.question}
          </div>
          {answers}
        </>
      </List.Item>
    )
  }

  const collapse = () => {
    setCollapsed(!isCollapsed)
  }

  return (
    <Dashboard selectedMenu={3} navbar={t('アンケート')}>
      <div className="mt-3 flex flex-col justify-center items-center ml-20">
        <div style={{ minWidth: 930, maxWidth: '100vw' }}>
          <div
            className={`border border-primary rounded-xl px-10 py-2 ${
              isCollapsed && 'h-16 overflow-hidden'
            }`}
          >
            {school && (
              <List
                className="flex flex-col items-center space-y-1"
                itemLayout="vertical"
                dataSource={questionData}
                renderItem={(question, idx) => renderQuestion(question, idx)}
                bordered={false}
                pagination={{
                  size: 'small',
                  showTitle: false,
                  hideOnSinglePage: true,
                  showSizeChanger: false,
                  pageSize: 4,
                  position: 'bottom',
                }}
              />
            )}
          </div>
          <div className="flex justify-center items-center mt-2">
            <Button
              type="link"
              icon={
                isCollapsed ? (
                  <DownOutlined style={{ verticalAlign: 'middle' }} />
                ) : (
                  <UpOutlined style={{ verticalAlign: 'middle' }} />
                )
              }
              onClick={collapse}
            >
              {isCollapsed ? t('質問をもっと見る') : t('質問を閉じる')}
            </Button>
          </div>

          <div className="w-full space-x-2">
            <Select
              value={schoolGrade}
              onChange={setSchoolGrade}
              className="w-32 rounded-5px pl-10"
              dropdownAlign={{
                offset: [0, -2],
              }}
            >
              <Option key={'grade-default'} value={0}>
                {t('totalGrades')}
              </Option>
              {grades?.data?.map((v) => (
                <Option value={v.grade} key={v.grade}>
                  {t('個別学年', {
                    count: v.grade,
                    ordinal: !isUsingJp,
                  })}
                </Option>
              ))}
            </Select>

            <Select
              value={schoolClass}
              onChange={setSchoolClass}
              className="w-32 rounded-5px pl-10"
              dropdownAlign={{
                offset: [0, -2],
              }}
            >
              <Option key={'class-default'} value={0}>
                {t('全組')}
              </Option>
              {schoolClasses.map((_class) => {
                return (
                  <Option key={`class-${_class}`} value={_class}>
                    {t('個別組', {
                      count: _class,
                      ordinal: !isUsingJp,
                    })}
                  </Option>
                )
              })}
            </Select>
          </div>

          <div className="w-full mt-1">
            {t('生徒登録人数', {
              student: isElementarySchool ? '児童' : '生徒',
            })}
            : {surveys?.total}
          </div>
          {listQuestion.length && (
            <TableQuestion
              dataDefault={data}
              listQuestion={listQuestion}
              questionExtra={questionExtra}
              questionData={questionData}
              total={total}
              pageSize={pageSize}
              setPageSize={setPageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isLoading={gradesLoading || surveysLoading}
            />
          )}
        </div>
      </div>
    </Dashboard>
  )
}

export default QuestionnaireInputPage
