import React, { MouseEventHandler, useEffect, useState } from 'react'

import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Form, Input, InputNumber, message, Modal, Select, Table } from 'antd'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  QuestionnaireV3Result,
  SchoolReportTestByGrade,
  TestResult,
} from '~/alpha/core'
import { useAlphaStore } from '~/context'
import { useLoadingPercent } from '~/hooks/useLoadingPercent'
import i18nIndex from '~/i18n'
import { updateSurvey } from '~/services/api/questions'
import { QuestionTestResult, Survey, Surveys } from '~/types/api/survey'
import Api from '~/utils/api'
import {
  getIsOptionalQuestions,
  getSortedQuestionnairesIdArr,
} from '~/utils/questions'
import { objPropertyToString } from '~/utils/string'
import { AnswerType, Question, StudentQuestionExtra } from '~/utils/types'
import { parseDisplayValueToValidateNumber } from '~/utils/validations'
import { checkIfCompletedSurvey } from '../pages/question-result/utils/status'
import CircularProgressLoading from '../shared/CircularProgressLoading'
import { incorrectValue } from '../shared/IncorrectValue'

const { Option } = Select

const sortTestResults = (a, b, key: string) => {
  let element1: string | number | undefined
  let element2: string | number | undefined

  if (key.startsWith('q')) {
    const a_qV3 = a.attributes?.testResults?.[0]?.questionnaireV3
    const b_qV3 = b.attributes?.testResults?.[0]?.questionnaireV3
    element1 = a_qV3?.[key]
    element2 = b_qV3?.[key]
  } else if (key === 'name') {
    element1 = `${a?.attributes?.familyName ?? ''}${
      a?.attributes?.givenName ?? ''
    }`
    element2 = `${b?.attributes?.familyName ?? ''}${
      b?.attributes?.givenName ?? ''
    }`
  } else {
    element1 = a?.[key]
    element2 = b?.[key]
  }

  if (element1 == null && element2 == null) return 1
  if (element1 == null) return -1
  if (element2 == null) return 1

  return typeof element1 === 'number'
    ? element1 - (element2 as number)
    : element1.localeCompare(element2 as string)
}

let isOptionalQuestions: boolean[] | undefined

const columns = (
  totalQuestionnaires: number[],
  _isOptionalQuestions: boolean[] | undefined,
  locale: 'ja' | 'en',
): any[] => [
  {
    title: { ja: '登録状況', en: 'Status' }[locale],
    dataIndex: ['attributes', 'testResults'],
    key: 'status',
    className: 'text-center-f whitespace-nowrap',
    sorter: (a, b) => {
      const isCompletedSurvey1 = checkIfCompletedSurvey(
        totalQuestionnaires,
        a?.attributes?.testResults,
        _isOptionalQuestions,
      )

      const isCompletedSurvey2 = checkIfCompletedSurvey(
        totalQuestionnaires,
        b?.attributes?.testResults,
        _isOptionalQuestions,
      )

      return isCompletedSurvey1 === isCompletedSurvey2
        ? 0
        : !isCompletedSurvey1
        ? -1
        : 1
    },

    sortDirections: ['descend', 'ascend'],
    render: (
      studentTestResults: SchoolReportTestByGrade[] | TestResult[] | undefined,
    ) => {
      const status = checkIfCompletedSurvey(
        totalQuestionnaires,
        studentTestResults,
        _isOptionalQuestions,
      )

      return (
        <div
          className="flex justify-center items-center"
          style={{ height: 22, width: 60 }}
        >
          <div
            className="flex justify-center items-center h-4 w-4 rounded-3px text-xxs font-bold text-white"
            style={{ backgroundColor: status ? '#00944D' : '#CA4141' }}
          >
            {status ? '済' : '未'}
          </div>
        </div>
      )
    },
  },
  {
    title: { ja: '年', en: 'Grade' }[locale],
    key: 'schoolGrade',
    dataIndex: ['attributes', 'schoolGrade'],
    className: 'text-center-f whitespace-nowrap',
    sorter: (a, b) => {
      return sortTestResults(a, b, 'schoolGrade')
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => <div style={{ width: 40 }}>{v}</div>,
  },
  {
    title: { ja: '組', en: 'Class' }[locale],
    key: 'schoolClass',
    dataIndex: ['attributes', 'schoolClass'],
    className: 'text-center-f whitespace-nowrap',
    sorter: (a, b) => {
      return sortTestResults(a, b, 'schoolClass')
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => <div style={{ width: 50 }}>{v}</div>,
  },
  {
    title: { ja: '番', en: 'Number' }[locale],
    key: 'schoolAttendanceNumber',
    dataIndex: ['attributes', 'schoolAttendanceNumber'],
    className: 'text-center-f whitespace-nowrap',
    sorter: (a, b) => {
      return sortTestResults(a, b, 'schoolAttendanceNumber')
    },
    sortDirections: ['descend', 'ascend'],
    render: (v) => <div style={{ width: 70 }}>{v}</div>,
  },
  {
    title: { ja: '名前', en: 'Name' }[locale],
    key: 'name',
    dataIndex: ['attributes'],
    className: 'text-center-f whitespace-nowrap',
    sorter: (a, b) => {
      return sortTestResults(a, b, 'name')
    },
    sortDirections: ['descend', 'ascend'],
    render: (v, _) => (
      <div style={{ width: 140 }}>
        {
          {
            ja: `${v.familyName ?? ''} ${v.givenName ?? ''}`,
            en: `${v.givenName ?? ''} ${v.familyName ?? ''}`,
          }[locale]
        }
      </div>
    ),
  },
]

const TableQuestion = ({
  total,
  dataDefault,
  questionExtra,
  questionData,
  listQuestion,
  pageSize,
  setPageSize,
  currentPage,
  setCurrentPage,
  isLoading,
}: {
  dataDefault: Survey[] | undefined
  listQuestion: number[]
  questionExtra: StudentQuestionExtra
  questionData: Question[]
  total: number
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  isLoading: boolean
}): JSX.Element => {
  const { school } = useAlphaStore()

  const { t } = useTranslation()

  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  const prefectureCode = school?.attributes?.prefectureCode

  const totalQuestionnaires: number[] = prefectureCode
    ? getSortedQuestionnairesIdArr(
        prefectureCode,
        school?._id,
        isElementarySchool,
      )
    : []

  useEffect(() => {
    // reset the list
    isOptionalQuestions = getIsOptionalQuestions(listQuestion)
  }, [listQuestion])

  const [surveys, setSurveys] = useState<Survey[]>()
  const [editingId, setEditingId] = useState('')
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [deletedStudent, setDeletedStudent] = useState<undefined | Survey>()

  const handleCancel = () => {
    setDeletedStudent(undefined)
  }

  const editRow = (student: Surveys['data'][0]) => {
    setEditingId(student._id)
    const convertObj = objPropertyToString(
      student.attributes?.testResults[0]?.questionnaireV3 || {},
    )

    form.resetFields()
    form.setFieldsValue(convertObj)
  }

  const deleteRow = async () => {
    const deletedStudentId = (deletedStudent as Survey)._id
    setIsTableLoading(true)

    await Api.delete(`/alpha/v1/school/survey/student/${deletedStudentId}`)
    for (const student of surveys as Survey[]) {
      if (
        student._id === deletedStudentId &&
        student.attributes.testResults[0]
      ) {
        student.attributes.testResults[0].questionnaireV3 = null
      }
    }

    setDeletedStudent(undefined)
    setIsTableLoading(false)
  }

  useEffect(() => {
    setSurveys(dataDefault)
  }, [dataDefault])

  const [form] = Form.useForm()

  const handleSave = async (id: string) => {
    if (!school) {
      message.error(`${t('エラーが発生しました。')} School is not available!`)
      return
    }

    let questionnaire: Record<string, number>

    try {
      questionnaire = await form.validateFields()
    } catch (err) {
      console.error(`Validate failed ${(err as Error).message}`)
      return
    }

    const newData = [...(surveys as Survey[])]
    const index = newData.findIndex((item) => id === item._id)
    const item = _.cloneDeep(newData[index])
    if (item.attributes.testResults.length === 0)
      item.attributes.testResults.push({} as QuestionTestResult)

    const { testResults } = item.attributes
    testResults[0].questionnaireV3 = questionnaire
    newData.splice(index, 1, {
      ...item,
    })

    setSurveys(newData)
    setEditingId('')

    await updateSurvey([
      {
        schoolGrade: item.attributes.schoolGrade,
        schoolClass: item.attributes.schoolClass,
        schoolAttendanceNumber: item.attributes.schoolAttendanceNumber,
        questionnaire,
      },
    ])
  }

  const EditableCell = ({
    editing,
    title,
    editable,
    children,
    dataIndex,
    record,
    ...restProps
  }: {
    editing: boolean
    title: string
    editable: boolean
    children: JSX.Element
    dataIndex: string
    record: Survey
    className: string
    style: unknown
    colSpan?: number | null
    rowSpan?: number | null
    onMouseEnter: MouseEventHandler<HTMLTableCellElement>
    onMouseLeave: MouseEventHandler<HTMLTableCellElement>
  }): JSX.Element => {
    let childNode = children

    if (editable) {
      let isSelect = false
      // dataIndex: "q1" => questionId = "1"
      const questionId = Number(dataIndex.slice(1)) - 1

      let max = '8'
      let min = '0'
      if (listQuestion[questionId] === 45) {
        isSelect = true
      }

      const currentQuestion = questionData[questionId]
      if (currentQuestion) {
        max = `${Object.keys(currentQuestion.options).length}`
      }

      if (
        currentQuestion?.answerType &&
        currentQuestion.answerType !== AnswerType.options
      ) {
        min = '0'
        max = '1000'
      }

      let inputComponent = !isSelect ? (
        <InputNumber
          style={{ width: 50, textAlign: 'left' }}
          size="small"
          min={min}
          max={max}
          parser={parseDisplayValueToValidateNumber}
        />
      ) : (
        <Select style={{ width: 150, textAlign: 'left', marginTop: 4 }}>
          {Object.keys(questionExtra).map((key) => (
            <Option key={key} value={key}>
              {key}.{questionExtra[key].name}
            </Option>
          ))}
        </Select>
      )

      if (currentQuestion.answerType === AnswerType.multipleSelect) inputComponent = (
        <Input
          style={{ width: 80, textAlign: 'left' }}
          size="small"
        />
      )

      // when user deletes the current value from input, it's value will be `null`.
      childNode = editing ? (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: !currentQuestion.isOptional,
              message: t('{{title}}を入力してください', { title }),
            },
          ]}
        >
          {inputComponent}
        </Form.Item>
      ) : (
        children
      )
    }

    return <td {...restProps}>{childNode}</td>
  }

  const components = {
    body: {
      cell: EditableCell,
    },
  }

  const isEditing = (record: Survey) => record._id === editingId

  const editableColumns = [
    ...columns(
      totalQuestionnaires,
      isOptionalQuestions,
      i18nIndex.language === 'en' ? 'en' : 'ja',
    ),
    ...listQuestion.map((questionKey, index) => {
      const qk = `q${index + 1}`
      return {
        title: `Q${index + 1}`,
        dataIndex: qk,
        key: qk,
        className: 'text-center-f whitespace-nowrap bg-white',
        editable: true,
        sorter: (a, b) => {
          return sortTestResults(a, b, qk)
        },
        sortDirections: ['descend', 'ascend'],

        render: (_v, r) => {
          if (
            !r.attributes?.testResults ||
            r.attributes?.testResults.length === 0
          )
            return incorrectValue

          const questionnaireV3: QuestionnaireV3Result | undefined =
            r.attributes.testResults[0]?.questionnaireV3

          const answer = questionnaireV3?.[qk]

          if (answer == null) return incorrectValue

          if (questionKey === 45) {
            if (!answer || !questionExtra[answer]) {
              return incorrectValue
            }

            return (
              <div>
                {answer}.{questionExtra[answer].name}
              </div>
            )
          }

          return <div>{answer}</div>
        },
      }
    }),
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      classNAme: 'text-center',
      editable: false,
      render: (v, record: Survey) => {
        const editable = isEditing(record)
        return editable ? (
          <SaveOutlined
            className="cursor-pointer"
            onClick={() => handleSave(record._id)}
          />
        ) : (
          <div className="flex space-x-2">
            <EditOutlined
              className="cursor-pointer"
              onClick={() => editRow(record)}
            />
            <DeleteOutlined
              className="cursor-pointer color-red-f"
              onClick={() => setDeletedStudent(record)}
            />
          </div>
        )
      },
    },
  ].map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record: Survey) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  const loadingPercent = useLoadingPercent()

  return (
    <>
      <Form form={form} component={false}>
        <Table
          columns={editableColumns}
          components={components}
          dataSource={surveys}
          loading={{
            spinning: isLoading || isTableLoading,
            indicator: <CircularProgressLoading percent={loadingPercent} />,
          }}
          style={{ minWidth: 930, maxWidth: '100vw' }}
          rowKey="_id"
          size="small"
          rowClassName="font-bold text-black"
          bordered={true}
          pagination={{
            pageSize,
            defaultPageSize: pageSize,
            position: ['bottomCenter'],
            showSizeChanger: true,
            total,
            current: currentPage,
            pageSizeOptions: [10, 20, 50, 100, 1000, 2000],
            onChange: (page: number, pageSize: number) => {
              setCurrentPage(page)
              setPageSize(pageSize)
            },
          }}
        />
      </Form>

      <Modal
        title={
          <span
            style={{
              fontWeight: 700,
              fontSize: 20,
              lineHeight: '30px',
            }}
          >
            {t('アンケート結果を削除しますか？')}
          </span>
        }
        visible={!!deletedStudent}
        footer={null}
        closable={false}
        className="mt-4"
      >
        <div
          className="justify-center flex"
          style={{
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '21px',
            margin: '-24px -24px 0',
            padding: '24px 0',
            borderTop: '1px solid #C4C4C4',
            borderBottom: '1px solid #C4C4C4',
          }}
        >
          <span>
            {deletedStudent &&
              t(
                '{{familyName}} {{givenName}}さんのアンケート結果を削除します。',
                {
                  givenName: deletedStudent.attributes.givenName,
                  familyName: deletedStudent.attributes.familyName,
                },
              )}
          </span>
        </div>
        <div className="mt-8 justify-center flex">
          <Button
            className="w-60 mr-2"
            size="large"
            onClick={(e) => {
              handleCancel()
            }}
          >
            {t('キャンセル')}
          </Button>
          <Button
            type="primary"
            className="w-60 ml-2"
            size="large"
            onClick={() => {
              deleteRow()
            }}
          >
            {t('削除')}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default TableQuestion
