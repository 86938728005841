import axios from 'axios'

export const fetchSchoolClasses = (
  schoolId,
  testYear,
): Promise<{ schoolClasses: number[] }> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_REST_API_URL}/alpha/max-class`, {
        schoolId,
        testYear,
      })
      .then((res) => {
        const maxClass = res.data.maxClass
        let classes: number[] = []
        if (maxClass) {
          classes = Array.from({ length: maxClass }, (_, i) => i + 1)
        }
        resolve({ schoolClasses: classes })
      })
  })
}
