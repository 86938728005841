import { useEffect, useState } from 'react'

import { Col, Row, Select } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAlphaStore } from '~/context'
import Api from '~/utils/api'
import Video from '../cards/Video'
import { Dashboard } from '../layout/Dashboard'

const { Option } = Select

/**
 * Path: /exercise_program
 */
const ExerciseProgramPage = () => {
  const [listSports, setListSports] = useState<any>([])
  const [sport, setSport] = useState('握力')
  const [data, setData] = useState<any[]>([])

  const { school } = useAlphaStore()
  const { t } = useTranslation()

  const getFilterData = async () => {
    const apiUrl = '/alpha/v1/school/filterVideo'

    const { data: dataFilter } = await Api.get(apiUrl)
    setListSports(dataFilter?.data?.listSports)
    setSport(dataFilter?.data?.listSports[0])
  }

  useEffect(() => {
    getFilterData().then()
  }, [school])

  const getStudentData = async () => {
    setData([])

    const apiUrl = `/alpha/v1/school/getVideo?sportName=${sport}`

    const { data } = await Api.get(apiUrl)
    setData(data.data)
  }

  useEffect(() => {
    getStudentData().then()
  }, [sport])

  return (
    <Dashboard selectedMenu={5} navbar={t('個人でできる運動プログラム')}>
      {
        <Row className="w-full justify-center">
          <Col className="mt-16" xxl={{ span: 20 }} lg={{ span: 22 }}>
            <div className="space-x-10 pb-7 print:hidden flex justify-center">
              <div className="flex items-center">
                <span className="mr-2">{t('種目')}</span>
                <Select
                  className="w-40 rounded-5px"
                  value={sport}
                  onChange={setSport}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <>
                    {listSports.map((v) => (
                      <Option value={v} key={v}>
                        {t(v)}
                      </Option>
                    ))}
                  </>
                </Select>
              </div>
            </div>
            <div className="flex justify-center">
              <Row style={{ width: 1200 }}>
                {data?.map((m, index) => (
                  <div
                    className={`${
                      data.length < 4 ? 'w-1/3' : 'w-1/4'
                    } pl-2 list-video mb-2`}
                    key={`advise-${index + 1}`}
                  >
                    <Video
                      src={m.url}
                      size={{ width: 292, height: 164 }}
                      thumb={m.thumb}
                      className="rounded-10px overflow-hidden"
                    />
                    <Row className="justify-between px-3">
                      <div>
                        <div>
                          <span className="mt-1 text-black">
                            {t(m?.sportName)}
                          </span>
                        </div>
                        {/* <div className="text-black mt-1 text-12px">
                          {m?.schoolCategoryCode?.includes('B1') ? '小学生向け' : '中高生向け'}
                        </div> */}
                      </div>
                      <div style={{ marginRight: 20 }}>
                        {m?.ability?.length > 0
                          ? m?.ability?.map((item, idx) => (
                              <div
                                className="text-black text-12px mt-1"
                                key={idx.toString()}
                              >
                                {t(item)}
                              </div>
                            ))
                          : null}
                      </div>
                    </Row>
                  </div>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      }
    </Dashboard>
  )
}

export default ExerciseProgramPage
