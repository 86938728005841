import { useState } from 'react'
import { read, set_cptable, utils } from 'xlsx'
import * as cp_table from 'xlsx/dist/cpexcel.full.mjs'

import { UploadOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Typography, Upload } from 'antd'

import { useTranslation } from 'react-i18next'
import { Dashboard } from '../layout/Dashboard'

const { Text } = Typography

set_cptable(cp_table)

/**
 * not in menu page
 */
const ImportQuestion = () => {
  const [data, setData] = useState<any[]>([])

  const [setUploadFile] = useState(null)

  const { t } = useTranslation()

  const parseXlsx = async (file) => {
    const _students = []
    const data = read(await file.arrayBuffer())

    const firstSheetName = data.SheetNames[0]
    const workBook = data.Sheets

    if (firstSheetName && workBook) {
      const rows = utils.sheet_to_json(workBook[firstSheetName])
      for (const row of rows) {
        const options = {}
        for (let a = 1; a <= 8; a++) {
          if (row[a]) {
            options[row[a]] = a
          }
        }
        const kanji1 = []
        for (let b = 1; b <= 41; b = b + 2) {
          if (row['質問内容' + b]) {
            kanji1.push({
              kanji: row['質問内容' + b],
              hina: row['質問内容' + (b + 1)],
            })
          }
        }
        const optionsKanji = {}
        for (let x = 1; x <= 8; x++) {
          const kanji2 = []
          for (let c = 0; c <= 9; c = c + 2) {
            if (row['回答内容' + x + c]) {
              kanji2.push({
                kanji: row['回答内容' + x + c],
                hina: row['回答内容' + x + (c + 1)],
              })
            }
          }
          optionsKanji[x] = kanji2
        }

        _students.push({
          type: 'elementary',
          question: row['desc'],
          id: row['id'],
          name: row['name'],
          options: options,
          kanjiQuestion: kanji1,
          kanjiOption: optionsKanji,
        })
      }
      setData(_students)
    }
  }

  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(data),
    )}`
    const link = document.createElement('a')
    link.href = jsonString
    link.download = 'questions_all.json'

    link.click()
  }

  return (
    <Dashboard selectedMenu={5} navbar={t('個人でできる運動プログラム')}>
      <div className="steps-content flex flex-col items-center justify-center">
        <div className="h-14 border p-3 border-warn">
          <WarningOutlined className="text-3xl warn-icon" />
          <Text type="danger" className="font-black">
            ①でダウンロードしたファイルをアップロードしてください。
          </Text>
        </div>
        <div className="flex mt-6">
          <Upload
            multiple={false}
            name="logo"
            listType="text"
            maxCount={1}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            beforeUpload={(file) => {
              parseXlsx(file)
              setUploadFile(file)
              return false
            }}
            onRemove={() => {
              setData([])
            }}
          >
            <div className="flex">
              <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
                <UploadOutlined className="text-2xl" />
              </div>
              <Button type="primary">生徒情報をアップロード</Button>
            </div>
          </Upload>
        </div>
        <Text className="mb-36">(XLSXファイル式)</Text>
        <Button type="primary" onClick={exportData}>
          Export Data
        </Button>
      </div>
    </Dashboard>
  )
}

export default ImportQuestion
