import { Col, Row, Select, Table, Tag } from 'antd'
import { ColumnType } from 'antd/es/table'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAlphaStore } from '~/context'
import { schoolHasEnduranceTest } from '~/utils'
import Api from '~/utils/api'
import { currentYear } from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import { ITestValue } from '~/utils/test-result'
import Video from '../cards/Video'
import { Dashboard } from '../layout/Dashboard'

interface ITestValueAndKey extends ITestValue {
  key: string
  type: string
}

interface ICalcTScore {
  title: string
  key: string
  value: number | null
}

const { Option } = Select

const showRankingTag = {
  1: {
    color: 'green',
    text: '初級',
  },
  2: {
    color: 'orange',
    text: '中級',
  },
  3: {
    color: 'red',
    text: '上級',
  },
} as const

const findWarning = (calcTScore, key) => {
  let warning = false
  if (calcTScore && calcTScore?.length > 0) {
    for (let i = 0; i < calcTScore.length; i++) {
      if (key === calcTScore[i].key) {
        warning = !!(calcTScore[i]?.value < 50)
        break
      }
    }
  }
  return warning
}

const convertData = (
  list: any[],
  isEnduranceRun: boolean,
  calcTScore?: ICalcTScore[],
): ITestValue => {
  const dataForm: ITestValue = isEnduranceRun
    ? {
        grip: '',
        sitUps: '',
        bending: '',
        sprintRun: '',
        sideJump: '',
        // added enduranceRun
        enduranceRun: '',
        shuttleRun: '',
        standingJump: '',
        handballThrow: '',
      }
    : {
        grip: '',
        sitUps: '',
        bending: '',
        sprintRun: '',
        sideJump: '',
        shuttleRun: '',
        standingJump: '',
        handballThrow: '',
      }

  list.forEach((value) => {
    if (calcTScore) {
      dataForm[`${value.key}_WARNING`] = findWarning(calcTScore, value.key)
    }

    switch (value.key) {
      case 'GRIP_STRENGTH':
        dataForm.grip = value.value
        break
      case 'BENDING':
        dataForm.bending = value.value
        break
      case 'SIT_UPS':
        dataForm.sitUps = value.value
        break
      case 'SIDE_JUMP':
        dataForm.sideJump = value.value
        break
      case 'SHUTTLE_RUN':
        dataForm.shuttleRun = value.value
        break
      case 'ENDURANCE_RUN':
        dataForm.enduranceRun = value.value
        break
      case 'SPRINT_RUN':
        dataForm.sprintRun = value.value
        break
      case 'STANDING_JUMP':
        dataForm.standingJump = value.value
        break
      case 'HANDBALL_THROW':
        dataForm.handballThrow = value.value
        break
    }
  })

  return dataForm
}

/**
 * Path: /exercise_content
 */
const ExerciseContentPage = () => {
  const { school } = useAlphaStore()
  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)
  const testYear = currentYear

  const [listGrades, setListGrades] = useState<number[]>([])
  const [listClasses, setListClasses] = useState<number[]>([])
  const [schoolGrade, setSchoolGrade] = useState(1)
  const [schoolClass, setSchoolClass] = useState(1)
  const [data, setData] = useState<ITestValueAndKey[]>()
  const [reloading, setReloading] = useState(true)
  const [showBanner, setShowBanner] = useState(false)
  const [suggestionSports, setSuggestionSports] = useState<string[]>([])
  const [dataVideos, setDataVideos] = useState([])

  const isEnduranceRun = schoolHasEnduranceTest(school?.attributes)

  const [filterGender, setFilterGender] = useState('FEMALE')

  const getFilterData = async () => {
    const apiUrl = `/alpha/v1/school/filterStudents?testYear=${testYear}`
    const { data: dataFilter } = await Api.get(apiUrl)
    setListGrades(dataFilter?.data?.grades)
    setSchoolGrade(dataFilter?.data?.grades[0])
    setListClasses(dataFilter?.data?.classes)
    setSchoolClass(dataFilter?.data?.classes[0])
  }

  useEffect(() => {
    getFilterData().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getStudentData = async () => {
    setReloading(true)
    setDataVideos([])
    const dataParams = []
    dataParams.push(`testYear=${testYear}`)
    dataParams.push(`schoolGrade=${schoolGrade}`)
    dataParams.push(`schoolClass=${schoolClass}`)
    dataParams.push(`gender=${filterGender}`)

    const apiUrl = `/alpha/v1/school/getAveragePoints?${dataParams.join('&')}`

    const { data } = await Api.get(apiUrl)

    const _data = data?.data
    const dataTable: ITestValueAndKey[] = []

    dataTable.push({
      ...convertData(
        _data?.averagePoints ?? [],
        isEnduranceRun,
        _data?.calcTScore || [],
      ),
      type: t('組'),
      key: t('平均'),
    })

    dataTable.push({
      ...convertData(_data?.calcTScore ?? [], isEnduranceRun),
      type: t('組1'),
      key: t('Tスコア'),
    })
    dataTable.push({
      ...convertData(_data?.averagePointsNational ?? [], isEnduranceRun),
      type: t('全国'),
      key: t('平均'),
    })
    setData(dataTable)

    // {
    //   GRIP_STRENGTH: '握力',
    //   SIT_UPS: '上体起こし',
    //   BENDING: '長座体前屈',
    //   SHUTTLE_RUN: '20mシャトルラン',
    //   ENDURANCE_RUN: '持久走',
    //   SIDE_JUMP: '反復横とび',
    //   SPRINT_RUN: '50m走',
    //   STANDING_JUMP: '立ち幅とび',
    //   HANDBALL_THROW: 'ボール投げ',
    // }
    setSuggestionSports(_data?.suggestionSport ?? [])

    setDataVideos(_data?.suggestVideo)
    setShowBanner(_data?.showBanner)
    setReloading(false)
  }

  useEffect(() => {
    getStudentData()
  }, [schoolClass, schoolGrade, filterGender])

  const tableColumns: ColumnType<any>[] = [
    {
      title: '',
      dataIndex: 'type',
      key: 'type',
      colSpan: 2,
      onCell: (_, index) => {
        if (index === 0) {
          return { rowSpan: 2 }
        }
        if (index === 1) {
          return { rowSpan: 0 }
        }

        return {}
      },
    },
    {
      colSpan: 0,
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: t('握力'),
      dataIndex: 'grip',
      key: 'grip',
      className: 'text-center-f whitespace-nowrap',
      render: (text, record) => {
        return {
          props: {
            style: record?.GRIP_STRENGTH_WARNING
              ? { background: '#F8E6E6' }
              : {},
          },
          children: <div>{text}</div>,
        }
      },
    },
    {
      title: t('上体起こし'),
      dataIndex: 'sitUps',
      key: 'sitUps',
      className: 'text-center-f whitespace-nowrap',
      render: (text, record) => {
        return {
          props: {
            style: record?.SIT_UPS_WARNING ? { background: '#F8E6E6' } : {},
          },
          children: <div>{text}</div>,
        }
      },
    },
    {
      title: t('長座体前屈'),
      dataIndex: 'bending',
      key: 'bending',
      className: 'text-center-f whitespace-nowrap',
      render: (text, record) => {
        return {
          props: {
            style: record?.BENDING_WARNING ? { background: '#F8E6E6' } : {},
          },
          children: <div>{text}</div>,
        }
      },
    },
    {
      title: t('反復横跳び'),
      dataIndex: 'sideJump',
      key: 'sideJump',
      className: 'text-center-f whitespace-nowrap',
      render: (text, record) => {
        return {
          props: {
            style: record?.SIDE_JUMP_WARNING ? { background: '#F8E6E6' } : {},
          },
          children: <div>{text}</div>,
        }
      },
    },
    {
      title: t('20mシャトルラン'),
      dataIndex: 'shuttleRun',
      key: 'shuttleRun',
      className: 'text-center-f whitespace-nowrap',
      render: (text, record) => {
        return {
          props: {
            style: record?.SHUTTLE_RUN_WARNING ? { background: '#F8E6E6' } : {},
          },
          children: <div>{text}</div>,
        }
      },
    },
    ...(isEnduranceRun
      ? [
          {
            title: t('持久走'),
            dataIndex: 'enduranceRun',
            key: 'enduranceRun',
            className: 'text-center-f whitespace-nowrap',
            render: (text, record) => {
              return {
                props: {
                  style: record?.ENDURANCE_RUN_WARNING
                    ? { background: '#F8E6E6' }
                    : {},
                },
                children: <div>{text}</div>,
              }
            },
          },
        ]
      : []),
    {
      title: t('50m走'),
      dataIndex: 'sprintRun',
      key: 'sprintRun',
      className: 'text-center-f whitespace-nowrap',
      render: (text, record) => {
        return {
          props: {
            style: record?.SPRINT_RUN_WARNING ? { background: '#F8E6E6' } : {},
          },
          children: <div>{text}</div>,
        }
      },
    },
    {
      title: t('立ち幅跳び'),
      dataIndex: 'standingJump',
      key: 'standingJump',
      className: 'text-center-f whitespace-nowrap',
      render: (text, record) => {
        return {
          props: {
            style: record?.STANDING_JUMP_WARNING
              ? { background: '#F8E6E6' }
              : {},
          },
          children: <div>{text}</div>,
        }
      },
    },
    {
      title: t('ボール投げ'),
      dataIndex: 'handballThrow',
      key: 'handballThrow',
      className: 'text-center-f whitespace-nowrap',
      render: (text, record) => {
        return {
          props: {
            style: record?.HANDBALL_THROW_WARNING
              ? { background: '#F8E6E6' }
              : {},
          },
          children: <div>{text}</div>,
        }
      },
    },
  ]

  return (
    <Dashboard
      selectedMenu={5}
      navbar={t('集計結果から最適化された運動コンテンツ')}
    >
      {
        <Row className="w-full justify-center">
          <Col className="mt-16" xxl={{ span: 20 }} lg={{ span: 22 }}>
            <div className="space-x-10 pb-2 print:hidden flex">
              <div className="flex items-center">
                <span className="mr-2">{t('学年')}</span>
                <Select
                  className="w-30 rounded-5px"
                  value={schoolGrade}
                  onChange={setSchoolGrade}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <>
                    {listGrades.map((v) => (
                      <Option value={v} key={v}>
                        {t('個別学年', {
                          count: v,
                          ordinal: !isUsingJp,
                        })}
                      </Option>
                    ))}
                  </>
                </Select>
              </div>
              <div className="flex items-center ml-10">
                <span className="mr-2">{t('組')}</span>
                <Select
                  className="w-30 rounded-5px"
                  value={schoolClass}
                  onChange={setSchoolClass}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <>
                    {listClasses.map((_class) => {
                      return (
                        <Option key={`class-${_class}`} value={_class}>
                          {t('個別組', {
                            count: _class,
                            ordinal: !isUsingJp,
                          })}
                        </Option>
                      )
                    })}
                  </>
                </Select>
              </div>
              <div className="flex items-center ml-10">
                <span className="mr-2">{t('性別')}</span>
                <Select
                  className="w-30 rounded-5px"
                  value={filterGender}
                  onChange={(g) => setFilterGender(g)}
                  dropdownAlign={{
                    offset: [0, -2],
                  }}
                >
                  <Option key="gender-male" value="MALE">
                    {t('男性')}
                  </Option>
                  <Option key="gender-female" value="FEMALE">
                    {t('女性')}
                  </Option>
                </Select>
              </div>
            </div>
            <div style={{ width: 966, margin: 'auto' }}>
              <div className="justify-center mt-8">
                <Table
                  columns={tableColumns}
                  dataSource={data}
                  loading={reloading}
                  rowKey="type"
                  size="small"
                  pagination={false}
                  bordered={true}
                />
              </div>
              {showBanner ? (
                <div className="mt-10">
                  <img src="exercise_done.png" alt="" />
                </div>
              ) : (
                <>
                  <div className="mt-2 mb-10 flex items-center">
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: '21px',
                        color: '#036EB8',
                      }}
                    >
                      {`${t('全国平均値を下回った種目')}:`}
                    </span>
                    &nbsp;
                    <span
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: '24px',
                        color: '#333333',
                      }}
                    >
                      {suggestionSports.map((item) => t(item)).join('・')}
                    </span>
                  </div>
                  <Row>
                    {dataVideos.map((m, index) => (
                      <div
                        className="w-1/4 pl-2 list-video mb-2"
                        key={`advise-${index + 1}`}
                      >
                        <Video
                          src={m.url}
                          size={{ width: 213, height: 140 }}
                          thumb={m.thumb}
                          className="rounded-10px overflow-hidden"
                        />
                        {m?.level && showRankingTag[m?.level]?.text ? (
                          <Tag color={showRankingTag[m.level]?.color}>
                            {t(showRankingTag[m.level]?.text)}
                          </Tag>
                        ) : null}

                        <Row className="justify-between px-3">
                          <div>
                            <div>
                              <span className="mt-1 text-black">
                                {t(m?.sportName)}
                              </span>
                            </div>
                            <div className="text-black mt-1 text-12px">
                              {m?.schoolCategoryCode?.includes('B1')
                                ? t('小学生向け')
                                : t('中高生向け')}
                            </div>
                          </div>

                          <div style={{ marginRight: 20 }}>
                            {m?.ability?.length > 0
                              ? m?.ability?.map((item, idx) => (
                                  <div
                                    className="text-black text-12px mt-1"
                                    key={idx.toString()}
                                  >
                                    {t(item)}
                                  </div>
                                ))
                              : null}
                          </div>
                        </Row>
                      </div>
                    ))}
                  </Row>
                </>
              )}
            </div>
          </Col>
        </Row>
      }
    </Dashboard>
  )
}

export default ExerciseContentPage
