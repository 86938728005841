export const findCode = (search?: string): string => {
  if (search && search?.length > 6) {
    return search.slice(6)
  }

  return ''
}

export const isChangeValue = (
  value: number | string | undefined,
  otherValue: number | string | undefined,
): boolean => {
  return value?.toString() !== otherValue?.toString()
}

export const objPropertyToString = (obj: { [x: string]: number | string }) => {
  Object.keys(obj).forEach((k) => {
    obj[k] = obj[k]?.toString() ?? ''
  })
  return obj
}

export const getStringFromValue = (
  value: string | number | null | undefined,
): undefined | string => {
  if (!value) return undefined
  return value.toString()
}
