import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useAlphaStore } from '~/context'
import { Dashboard } from '../layout/Dashboard'

const InputPage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { school } = useAlphaStore()

  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  return (
    <Dashboard selectedMenu={3} navbar={t('入力')}>
      <div className="grid-cols-2 grid mt-14 gap-4" style={{ color: 'black' }}>
        <div className="flex justify-end cursor-pointer">
          <div
            style={{
              padding: '44px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              borderRadius: 4,
              width: 400,
              height: 240,
            }}
            onClick={() => history.push('/test')}
          >
            <div className="flex justify-between mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('体力テスト')}
              </span>
              <img
                src="next_icon.png"
                alt=""
                style={{ width: 12, height: 20 }}
              />
            </div>
            <div>
              <span>
                {t('体力テストの結果を入力ができます。')}
                <br />
                {t('生徒が端末に入力した結果が反映されます。', {
                  student: !isElementarySchool ? '生徒' : '児童',
                })}
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-start cursor-pointer">
          <div
            style={{
              padding: '44px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              borderRadius: 4,
              width: 400,
              height: 240,
            }}
            onClick={() => history.push('/questionnaire')}
          >
            <div className="flex justify-between mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('アンケート')}
              </span>
              <img
                src="next_icon.png"
                alt=""
                style={{ width: 12, height: 20 }}
              />
            </div>
            <div>
              <span>
                {t('アンケートの結果を入力ができます。')}
                <br />
                {t('生徒が端末に入力した結果が反映されます。', {
                  student: !isElementarySchool ? '生徒' : '児童',
                })}
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-2 cursor-pointer">
          <div
            style={{
              padding: '44px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              borderRadius: 4,
              width: 400,
              height: 240,
            }}
            onClick={() => history.push('/upload-excel')}
          >
            <div className="flex justify-between mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('Excelでアップロード')}
              </span>
              <img
                src="next_icon.png"
                alt=""
                style={{ width: 12, height: 20 }}
              />
            </div>
            <div>
              <span>
                {t(
                  '体力テスト及びアンケートを専用のExcelファイルを使用してアップロードすることができます。',
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-start mt-2 cursor-pointer">
          <div
            style={{
              padding: '44px 20px',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              borderRadius: 4,
              width: 400,
              height: 240,
            }}
            onClick={() => history.push('/date_of_birth')}
          >
            <div className="flex justify-between mb-8">
              <span style={{ fontSize: 20, fontWeight: 700 }}>
                {t('生年月日')}
              </span>
              <img
                src="next_icon.png"
                alt=""
                style={{ width: 12, height: 20 }}
              />
            </div>
            <div>
              <span>{t('生年月日の入力を行うことができます。')}</span>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default InputPage
