import './statistic.css'

import { Row } from 'antd'
import { TFunction } from 'i18next'
import { TEachTestRes } from '~/utils/points'
import { IPrefectureAverage } from '~/utils/prefecture-average/prefectureCodes'
import { TAllTestKey } from '~/utils/test-const'
import { getMinutes, getRemainingSeconds } from '~/utils/time'
import {
  IAverageValue,
  TEnduranceRun,
} from '../../../../../../utils/types/api/res'

type StatisticValueTableProps = {
  keyName: TAllTestKey
  title: string
  value: string | number
  result: TEachTestRes | undefined
  suffix: string
  points: number | string
  nationalAverage: IAverageValue | null
  comment: string | undefined
  prefectureAverage: false | IPrefectureAverage
  t: TFunction<'translation', undefined>
  isUsingJp: boolean
}

const StatisticValueTable = ({
  keyName,
  title,
  value,
  result,
  suffix,
  points,
  nationalAverage,
  comment,
  prefectureAverage,
  t,
  isUsingJp,
}: StatisticValueTableProps) => {
  const prefectureAverageVal = prefectureAverage
    ? prefectureAverage[keyName]
    : undefined

  const nationalAverageVal = nationalAverage
    ? nationalAverage[keyName]
    : undefined

  // test with longest comment string
  // uncomment the line below to test
  // alpha-school/src/utils/fitnessTest.ts'
  // length is 170:
  // comment =
  //   'はんぷくよことびはおなじうごきをくりかえします。じゅうしんをつま先におき、ひざをまげて、ひくくかまえましょう。あたまをまん中にのこして、すばやく足をうごかすのがコツです。ひだりとみぎのラインをこえようとすると、足をうごかすはばが広がってつかれやすくなるので、ラインはふむだけでもいいですね。リズムかんをつかんで、きろくをあげていきましょう。'

  // length is 327:
  // comment =
  //   "With your high abilities, consider training approaches. Engaging in  bounding (jumps alternating on each leg) and hopping (jumps on a single leg) involving the upper body is beneficial. Additionally, practice vertical jumps from a low stance. Focus on leg power and maintaining the coordination between legs and arms movements."

  // length is 180:
  // comment =
  //   'うんていや棒のぼり、つなや棒ひきなどをとおして、にぎる動作を日常的におこなってみましょう。これらの運動はきゅうに始めるとおりられなくなったり、手をけがしたりすることが多いですから十分に気を付けておこないましょう。また、家でもボールをにぎりしめたり、かぞくと一緒にタオルを引き合うなどのトレーニングもゆうこうです。き合うなどのトレーニングもゆうこうです。こうです'

  // length is 122:
  // comment =
  //   'うんていや棒のぼり、つなや棒ひきなどをとおして、にぎる動作を日常的におこなってみましょう。これらの運動はきゅうに始めるとおりられなくなったり、手をけがしたりすることが多いですから十分に気を付けておこないましょう。また、家でもボールをにぎりしめた'

  return (
    <>
      <Row className={`${isUsingJp ? 'text-xs' : 'text-10px'} font-bold`}>
        {title}
      </Row>
      <div className="font-bold flex">
        <div className="h-9 relative border-b-2 border-primary test-value">
          {keyName === 'enduranceRun' ? (
            <div className="h-full flex">
              <div className="w-1/2 h-full flex items-center justify-center relative">
                <div className="text-black">
                  {(result as TEnduranceRun)?.runningTime}
                </div>

                <div className="unit font-black absolute right-1 bottom-0.5">
                  分
                </div>
              </div>

              <div className="w-1/2 h-full flex items-center justify-center relative">
                <div className="text-black">
                  {(result as TEnduranceRun)?.runningTimeSeconds}
                </div>

                <div className="unit font-black absolute right-1 bottom-0.5">
                  秒
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="h-full flex items-center justify-center text-black">
                {value ?? '-'}
              </div>

              <div className="unit font-black absolute right-1 bottom-0.5">
                {suffix}
              </div>
            </>
          )}
        </div>
        <div className="w-4 border-b-2 border-primary" />

        {/* 得点 */}
        <div className="test-value h-9 border-b-2 border-primary flex items-center justify-center text-black">
          {points}
        </div>
        <div className="w-4 border-b-2 border-primary" />

        {/* 県平均 */}
        {prefectureAverage && (
          <>
            <div className="test-value h-9 relative border-b-2 border-primary">
              {keyName === 'enduranceRun' ? (
                <div className="h-full flex">
                  <div className="w-1/2 h-full flex items-center justify-center relative">
                    <div className="text-black">
                      {getMinutes(prefectureAverageVal)}
                    </div>
                    <div className="unit font-black absolute right-1 bottom-0.5">
                      {t('分')}
                    </div>
                  </div>
                  <div className="w-1/2 h-full flex items-center justify-center relative">
                    <div className="text-black">
                      {getRemainingSeconds(prefectureAverageVal)}
                    </div>
                    <div className="unit font-black absolute right-1 bottom-0.5">
                      {t('秒')}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="h-full flex items-center justify-center text-black">
                    {prefectureAverageVal ?? '-'}
                  </div>

                  <div className="unit font-black absolute right-1 bottom-0.5">
                    {suffix}
                  </div>
                </>
              )}
            </div>
            <div className="w-4 border-b-2 border-primary" />
          </>
        )}

        {/* 全国平均 */}
        {nationalAverageVal && (
          <>
            <div className="test-value h-9 relative border-b-2 border-primary">
              {keyName === 'enduranceRun' ? (
                <div className="h-full flex">
                  <div className="w-1/2 h-full flex items-center justify-center relative">
                    <div className="text-black">
                      {getMinutes(nationalAverageVal)}
                    </div>
                    <div className="unit font-black absolute right-1 bottom-0.5">
                      {t('分')}
                    </div>
                  </div>
                  <div className="w-1/2 h-full flex items-center justify-center relative">
                    <div className="text-black">
                      {getRemainingSeconds(nationalAverageVal)}
                    </div>
                    <div className="unit font-black absolute right-1 bottom-0.5">
                      {t('秒')}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="h-full flex items-center justify-center text-black">
                    {nationalAverageVal ?? '-'}
                  </div>

                  <div className="unit font-black absolute right-1 bottom-0.5">
                    {suffix}
                  </div>
                </>
              )}
            </div>
            <div className="w-4 border-b-2 border-primary" />
          </>
        )}

        <div
          className={
            'relative border-b-2 border-primary text-black comment w-full'
          }
        >
          {comment ? t(comment) : ''}
        </div>
        <div className="w-4" />
      </div>
    </>
  )
}

export default StatisticValueTable
