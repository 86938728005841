import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { AUTH_TOKEN_KEY, LOCAL_STORAGE_AUTH_TOKEN_KEY } from '~/alpha/core'
import { useAlphaStore } from '~/context'
import { usePopupStore } from '~/context/popup'
import { useEmailPhoneStore } from '~/context/user'
import { clearLocalStorage } from '~/school-utils/storages'
import { ITeacher } from '~/types/api/alpha/teachers'
import Api from '~/utils/api'
import { CommonError } from '~/utils/error'

const schoolMeApiUrl = '/alpha/v1/school/me'
const popupApiUrl = '/alpha/v1/school/popups'

/**
 * Alpha school API
 */
export const useSchoolMeApiQuery = (): {
  data: ITeacher | undefined
  loading: boolean
  error: CommonError | undefined
  refresh: () => void
} => {
  const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
  const [data, setData] = useState<ITeacher | undefined>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<CommonError | undefined>()

  const fetchedRef = useRef(false)
  const [refresh, setRefresh] = useState(false)
  const { setTeacher, setSchool } = useAlphaStore()
  const { setPhone, setEmail } = useEmailPhoneStore()
  const { setPopups } = usePopupStore()

  useEffect(() => {
    if (authToken && !fetchedRef.current) {
      new Promise(async () => {
        try {
          setLoading(true)
          const { data }: { data: ITeacher } = await Api.get(schoolMeApiUrl)
          setData(data)

          setTeacher(data)

          const schoolInfo = data.school?.attributes
          setPhone(schoolInfo?.telephone)
          setEmail(schoolInfo?.email)

          if (data.school) {
            setSchool(data.school)
          }

          setError(undefined)
          const { data: popups } = await Api.get(popupApiUrl)
          setPopups(popups)
        } catch (err: any) {
          setError(err)
          console.error(`apiUrl: ${schoolMeApiUrl} - error:`, err)

          if (err.response?.status === 401) {
            clearLocalStorage()
            delete axios.defaults.headers.common[AUTH_TOKEN_KEY]

            // keep this log
            console.log('When response status is unauthorized (401), logout!')
          }
        } finally {
          fetchedRef.current = false
          setLoading(false)
        }
      })
    } else {
      // keep this log
      console.log('Not found auth token when useSchoolMeApiQuery!')
      setLoading(false)
      setError(undefined)
    }
  }, [authToken, refresh, setTeacher])

  return {
    data: authToken ? data : undefined,
    loading,
    error,
    refresh: () => {
      setRefresh(!refresh)
    },
  }
}
