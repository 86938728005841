import {
  DownloadOutlined,
  UploadOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Select,
  Steps,
  Table,
  Typography,
  Upload,
  message,
} from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { read, set_cptable, utils } from 'xlsx'
import * as cp_table from 'xlsx/dist/cpexcel.full.mjs'

import type { RcFile } from 'antd/lib/upload'
import { useHistory } from 'react-router-dom'
import CompletionLayout from '~/components/layout/CompletionLayout'
import { useAlphaStore } from '~/context'
import { currentYear } from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import { getNonZeroPositiveNumberFromValue } from '~/utils/number'
import type { ICreatedStudents } from '~/utils/types/api/students'
import {
  getValidGenderFromJpText,
  getValidPositiveNumberFromExcelFile,
} from '~/utils/validations'
import { Dashboard } from '../layout/Dashboard'
import { tableColumns } from './student-list/components/table'

// WARN: When using English, given names and family names order is reversed,
// so the family name (Last Name) is saved in the given name (First Name) in DB.

const { Option } = Select
const { Step } = Steps
const { Text } = Typography

set_cptable(cp_table)

const displayNumbers = [
  { text: '20　件/ページ', value: 20 },
  { text: '50　件/ページ', value: 50 },
  { text: '100　件/ページ', value: 100 },
  { text: '1000　件/ページ', value: 1000 },
  { text: '2000　件/ページ', value: 2000 },
] as const

/**
 * Download from `./public` folder.
 *
 * @param isJapaneseLang
 */
const downloadTemplate = (isJapaneseLang: boolean) => {
  if (isJapaneseLang) {
    window.open(
      '/upload_sample/ALPHAアップロード用ファイル-児童生徒登録.xlsx',
      '_blank',
    )
  } else {
    window.open(
      '/upload_sample/ALPHA Student Registration sheet.xlsx',
      '_blank',
    )
  }
}

const StudentListPage = () => {
  const { teacher, school } = useAlphaStore()

  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  const [disabled, setDisabled] = useState(true)

  const [uploading, setUploading] = useState(false)

  const [createdStudents, setCreatedStudents] = useState<ICreatedStudents[]>([])

  const [registerComplete, setRegisterComplete] = useState(false)
  const [currentStepIdx, setCurrentStepIdx] = useState(0)
  const [confirmed1, setConfirmed1] = useState(false)
  const [confirmed2, setConfirmed2] = useState(false)
  const [confirmed3, setConfirmed3] = useState(false)
  const [pageSize, setPageSize] = useState(50)
  const [uploadFile, setUploadFile] = useState<RcFile | null>(null)

  const history = useHistory()
  const { t, i18n } = useTranslation()
  const isUsingJp = isJapanese(i18n)

  useEffect(() => {
    switch (currentStepIdx) {
      case 0:
        setDisabled(false)
        break
      case 1:
        setDisabled(!confirmed1)
        break
      case 2:
        setDisabled(!confirmed2)
        break
      case 3:
        setDisabled(!confirmed3)
        break
    }
  }, [currentStepIdx, confirmed1, confirmed2, confirmed3])

  const onFinish = async (): Promise<boolean> => {
    if (!teacher) {
      message.error(
        t('エラーが発生しました。しばらくしてからもう一回お試しください。'),
      )
      return false
    }

    if (!school) {
      message.error(`${t('エラーが発生しました。')} [school is not found]`)
      console.error('when call alpha/file-upload, error: school is not found')
      return false
    }

    setUploading(true)

    // upload backup file
    const schoolId = school._id
    const schoolName = school.attributes.schoolName

    /**
     * Determine whether when this file is uploaded.
     */
    let fileId = new Date().toISOString().split('T')[0]
    fileId = `student_list/${schoolName}_${schoolId}_${fileId}`

    const studentListFormData = new FormData()
    studentListFormData.append('file', uploadFile)
    studentListFormData.append('filename', fileId)

    let fileUrl: string

    try {
      await axios
        .post(
          `${process.env.REACT_APP_REST_API_URL}/alpha/file-upload`,
          studentListFormData,
        )
        .then((res) => {
          fileUrl = res.data.data
        })
        .catch((err: unknown) => {
          console.error('alpha/file-upload API error:', err)
          throw err
        })

      // upload students data
      const formData = new FormData()
      formData.set('testYear', `${currentYear}`)
      formData.set('userId', teacher._id)
      formData.set('schoolId', school._id)
      formData.set('filename', fileUrl)
      formData.set('students', JSON.stringify(createdStudents))

      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_REST_API_URL}/alpha/upload-students-data`,
        data: formData,
      })
        .then((response) => {
          const { status, error } = response.data

          if (status === 200) {
            message.success(t('アップロード完了'))
            // form.resetFields()
            setCreatedStudents([])
            setUploadFile(null)
            setTimeout(() => {
              setUploading(false)
            }, 1000)
          } else {
            if (error) {
              message.error(
                `${t('エラーが発生しました。')} [${JSON.stringify(error)}]`,
              )
            } else {
              message.error(
                `${t('エラーが発生しました。')} [status: ${status}]`,
              )
            }
          }
          setUploading(false)
        })
        .catch((err) => {
          console.error(err)
          message.error(t('エラーが発生しました。'))
          setUploading(false)
          throw err
        })
    } catch (err) {
      console.error('onFinish err:', err)
      return false
    }

    return true
  }

  const changePageSize = (value: number) => {
    setPageSize(value)
  }

  const register = async () => {
    const isSuccess = await onFinish()
    if (isSuccess) {
      setRegisterComplete(true)
    }
  }

  const next = async () => {
    const nextStepIdx = currentStepIdx + 1
    if (nextStepIdx === 3 && !uploadFile) {
      message.error(t('Excelファイルを選んでください。'))
      return
    }

    setCurrentStepIdx(nextStepIdx)
  }

  const prev = () => {
    setCurrentStepIdx(currentStepIdx - 1)
  }

  const parseXlsx = async (file: RcFile) => {
    const _students: ICreatedStudents[] = []
    const readData = read(await file.arrayBuffer())

    const firstSheetName = readData.SheetNames[0]
    const workBook = readData.Sheets

    if (firstSheetName && workBook) {
      const rows: Record<string, string | number | undefined | null>[] =
        utils.sheet_to_json(workBook[firstSheetName])
      let errRowIdsString = ''

      let gradeKey: string
      let classKey: string
      let studentNumberKey: string
      let familyNameKey: string
      let givenNameKey: string
      let givenNameReadingKey: string
      let familyNameReadingKey: string
      let sexKey: string
      let yearBirthKey: string
      let monthBirthKey: string
      let dayBirthKey: string

      if (isUsingJp) {
        gradeKey = '学年'
        classKey = '組'
        studentNumberKey = '出席番号'
        familyNameKey = '姓'
        givenNameKey = '名'
        givenNameReadingKey = 'せい'
        familyNameReadingKey = 'めい'
        sexKey = '性別'
        yearBirthKey = '生年月日（年）'
        monthBirthKey = '生年月日（月）'
        dayBirthKey = '生年月日（日）'
      } else {
        gradeKey = 'Grade'
        classKey = 'Class'
        studentNumberKey = 'Number'
        // WARN: When using English, given names and family names order is reversed,
        // so the family name (Last Name) is saved in the given name (First Name) in DB.
        familyNameKey = 'Given name'
        givenNameKey = 'Family name'
        givenNameReadingKey = 'せい'
        familyNameReadingKey = 'めい'
        sexKey = 'Sex'
        yearBirthKey = 'Date of Birth (year)'
        monthBirthKey = 'Date of Birth (month)'
        dayBirthKey = 'Date of Birth (date)'
      }

      for (let i = 0; i < rows.length; i++) {
        const row = rows[i]

        if (!row[familyNameKey] || !row[givenNameKey]) {
          const rowId = i + 1

          console.error(`row ${rowId} has invalid name:`, JSON.stringify(row))
          errRowIdsString += `${rowId}、`
          continue
        }

        const schoolGradeNumber = getValidPositiveNumberFromExcelFile(
          row[gradeKey],
        )
        const schoolClassNumber = getValidPositiveNumberFromExcelFile(
          row[classKey],
        )
        const schoolAttendanceNumber = getValidPositiveNumberFromExcelFile(
          row[studentNumberKey],
        )

        if (
          !schoolGradeNumber ||
          !schoolClassNumber ||
          !schoolAttendanceNumber
        ) {
          const rowId = i + 1

          console.error(
            `row ${rowId} has invalid grade/class/attendance:`,
            JSON.stringify(row),
          )
          errRowIdsString += `${rowId}、`
          continue
        }

        const gender = getValidGenderFromJpText(row[sexKey])

        if (!gender) {
          const rowId = i + 1
          console.error(`row ${rowId} has invalid gender:`, JSON.stringify(row))
          errRowIdsString += `${rowId}、`
          continue
        }

        _students.push({
          schoolGrade: schoolGradeNumber,
          schoolClass: schoolClassNumber,
          schoolAttendanceNumber,

          familyName: row[familyNameKey] as string,
          givenName: row[givenNameKey] as string,
          familyNameHiragana: row[givenNameReadingKey] as string,
          givenNameHiragana: row[familyNameReadingKey] as string,

          gender,
          yearBirth: getNonZeroPositiveNumberFromValue(row[yearBirthKey]),
          monthBirth: getNonZeroPositiveNumberFromValue(row[monthBirthKey]),
          dayBirth: getNonZeroPositiveNumberFromValue(row[dayBirthKey]),
        })
      }

      if (errRowIdsString.length > 0) {
        // remove unnecessary comma characters.
        errRowIdsString = errRowIdsString.substring(
          0,
          errRowIdsString.length - 1,
        )

        message.error(
          t('行番号{{ids}}に無効なデータがあります', {
            ids: errRowIdsString,
          }),
          7,
        )
      }

      _students.sort((a, b) => {
        if (a.schoolGrade !== b.schoolGrade)
          return a.schoolGrade - b.schoolGrade

        if (a.schoolClass !== b.schoolClass)
          return a.schoolClass - b.schoolClass

        return a.schoolAttendanceNumber - b.schoolAttendanceNumber
      })

      setCreatedStudents(_students)
    }
  }

  const steps = [
    {
      title: t('①登録用Excelファイルをダウンロード'),
      content: (
        <div className="steps-content flex items-center justify-center">
          <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
            <DownloadOutlined
              className="text-2xl"
              onClick={() => downloadTemplate(isUsingJp)}
            />
          </div>
          <Button type="primary" onClick={() => downloadTemplate(isUsingJp)}>
            {t('登録用Excelファイルをダウンロード')}
          </Button>
        </div>
      ),
    },
    {
      title: t('②記入例'),
      content: (
        <>
          <div className="steps-content flex flex-col items-center justify-between pt-5">
            <img
              className="mb-5"
              width={800}
              src={
                isUsingJp ? '/students_demo_ja.png' : '/students_demo_en.png'
              }
              alt=""
            />

            <Checkbox
              className="font-black"
              checked={confirmed1}
              onChange={(e) => setConfirmed1(e.target.checked)}
            >
              {t('記入例を確認しましたか？')}
            </Checkbox>
          </div>
          <br />

          {!isElementarySchool && (
            <div>
              ＜{`${t('学年について')}`}＞
              <br />
              {t(
                '義務教育学校の後期課程は、1年生〜3年生として登録をしてください。',
              )}
              <br />
              {t(
                '中等教育学校の後期課程は、1年生〜3年生として登録をしてください。',
              )}
              <br />
            </div>
          )}
        </>
      ),
    },
    {
      title: t('③生徒情報をアップロード'),
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <div className="h-14 border p-3 border-warn">
            <WarningOutlined className="text-3xl warn-icon" />
            <Text type="danger" className="font-black m-2">
              {t('①でダウンロードしたファイルをアップロードしてください。')}
            </Text>
          </div>

          <div className="flex mt-6">
            <Upload
              multiple={false}
              name="logo"
              listType="text"
              maxCount={1}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              beforeUpload={(file) => {
                parseXlsx(file)
                setUploadFile(file)
                return false
              }}
              onRemove={() => {
                setCreatedStudents([])
              }}
            >
              <div className="flex">
                <div className="border border-primary rounded-5px w-8 h-8 -mr-2">
                  <UploadOutlined className="text-2xl" />
                </div>
                <Button type="primary">{t('生徒情報をアップロード')}</Button>
              </div>
            </Upload>
          </div>

          <Text className="mb-36 mt-2">{t('XLSXファイル式')}</Text>

          <Checkbox
            className="font-black"
            checked={confirmed2}
            onChange={(e) => setConfirmed2(e.target.checked)}
          >
            {t('生徒情報を正しく記入しましたか？')}
          </Checkbox>
        </div>
      ),
    },
    {
      title: t('④確認'),
      content: (
        <div className="steps-content flex flex-col items-center justify-center">
          <div className="mb-5 w-full flex justify-start">
            <Select
              size="small"
              className="w-30 rounded-5px"
              placeholder={t('表示数')}
              value={pageSize}
              dropdownAlign={{
                offset: [0, -2],
              }}
              onChange={changePageSize}
            >
              {displayNumbers.map((d) => (
                <Option className="option-small" value={d.value} key={d.text}>
                  {t('件/ページ', { rows: d.value })}
                </Option>
              ))}
            </Select>
          </div>

          <Table
            columns={tableColumns(t, isUsingJp)}
            dataSource={createdStudents}
            rowKey={(record) =>
              `${record.schoolGrade}_${record.schoolClass}_${record.schoolAttendanceNumber}`
            }
            size="small"
            style={{ minWidth: 900 }}
            className="mb-4"
            rowClassName="font-bold text-black"
            bordered={true}
            pagination={{
              hideOnSinglePage: true,
              showSizeChanger: false,
              defaultPageSize: 50,
              pageSize: pageSize,
              position: ['bottomCenter'],
              total: createdStudents.length,
            }}
          />

          <Checkbox
            className="font-black"
            checked={confirmed3}
            onChange={(e) => setConfirmed3(e.target.checked)}
            disabled={createdStudents?.length === 0}
          >
            {t('記入したデータは正しいでしょうか？')}
          </Checkbox>
        </div>
      ),
    },
  ]

  return (
    <Dashboard
      selectedMenu={2}
      navbar={t('生徒登録', { student: isElementarySchool ? '児童' : '生徒' })}
    >
      {!registerComplete ? (
        <div className="flex justify-center">
          <div className="mt-16" style={{ minWidth: '680px' }}>
            <Steps
              labelPlacement="vertical"
              size="default"
              current={currentStepIdx}
              onChange={() => {}}
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[currentStepIdx].content}</div>
            <div className="steps-action text-center">
              {currentStepIdx > 0 && (
                <Button
                  type="primary"
                  className="h-8 w-24 mx-2"
                  onClick={() => prev()}
                >
                  {t('戻る')}
                </Button>
              )}
              {currentStepIdx < steps.length - 1 && (
                <Button
                  type="primary"
                  className="h-8 w-24"
                  loading={uploading}
                  onClick={() => next()}
                  disabled={disabled}
                >
                  {t('次へ')}
                </Button>
              )}
              {currentStepIdx === steps.length - 1 && (
                <Button
                  type="primary"
                  className="h-8 w-24"
                  loading={uploading}
                  onClick={() => register()}
                  disabled={disabled}
                >
                  {t('登録')}
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <CompletionLayout
          message={t('登録完了')}
          button={
            <Button
              type="primary"
              onClick={() => history.push('/school_year_info')}
            >
              {t('サインインコードを見に行く')}
            </Button>
          }
        />
      )}
    </Dashboard>
  )
}

export default StudentListPage
