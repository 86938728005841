import { Row } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Api from '~/utils/api'
import Video from '../cards/Video'
import { Dashboard } from '../layout/Dashboard'

// * API から取得するテキストに対する翻訳だが、ひとまず全て FE で翻訳テキストを持つ。改めて整理するとき再検討

/**
 * Path: /exercises
 */
const ExercisesPage = () => {
  const [dataVideos, setDataVideos] = useState([])
  const { t } = useTranslation()

  const getStudentData = async () => {
    const apiUrl = '/alpha/v1/school/getAllVideo'
    const { data } = await Api.get(apiUrl)
    setDataVideos(data?.data ?? [])
  }

  useEffect(() => {
    getStudentData().then()
  }, [])

  return (
    <Dashboard selectedMenu={5} navbar={t('授業で使える運動コンテンツ一覧')}>
      <Row justify="center" className="pt-5 list-video">
        <Row style={{ width: 1366 }}>
          {dataVideos?.map((m, index) => (
            <div
              className="w-1/4 pl-2 list-video mb-2"
              key={`advise-${index + 1}`}
            >
              <Video
                src={m.url}
                size={{ width: 292, height: 164 }}
                thumb={m.thumb}
                className="rounded-10px overflow-hidden"
              />
              <Row className="justify-between px-3">
                <div>
                  <div>
                    <span className="mt-1 text-black">{t(m?.sportName)}</span>
                  </div>

                  <div className="text-black mt-1 text-12px">
                    {m?.schoolCategoryCode?.includes('B1')
                      ? t('小学生向け')
                      : t('中高生向け')}
                  </div>
                </div>
                <div style={{ marginRight: 20 }}>
                  {m?.ability?.length > 0
                    ? m?.ability?.map((item, idx) => (
                        <div
                          className="text-black text-12px mt-1"
                          key={idx.toString()}
                        >
                          {t(item)}
                        </div>
                      ))
                    : null}
                </div>
              </Row>
            </div>
          ))}
        </Row>
      </Row>
    </Dashboard>
  )
}

export default ExercisesPage
