import { Col, Row, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { Bar, BarChart, Legend, XAxis, YAxis } from 'recharts'
import { UserGender } from '~/alpha/core'
import { TColumn } from '../types'
import { useAlphaStore } from '~/context'

const QuestionnaireTable = ({
  questionnaireSummary,
  gender,
  loading,
  listQuestion,
}) => {
  const { t } = useTranslation()
  const { school } = useAlphaStore()
  const isElementarySchool = school?.attributes?.schoolCategoryCode === 'B1'

  const isMale = gender === UserGender.Male
  const genderClass = isMale ? 'male' : 'female'
  const rowColor = isMale ? 'blue' : 'pink'

  const questionColumns: TColumn[] = [
    {
      title: t('質問'),
      dataIndex: 'question',
      key: 'question',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v) => (v ? v : null),
    },
    {
      title: '1',
      dataIndex: 'a1',
      key: 'a1',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v) => (v ? v : null),
    },
    {
      title: '2',
      dataIndex: 'a2',
      key: 'a2',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v) => (v ? v : null),
    },
    {
      title: '3',
      dataIndex: 'a3',
      key: 'a3',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v) => (v ? v : null),
    },
    {
      title: '4',
      dataIndex: 'a4',
      key: 'a4',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v) => (v ? v : null),
    },
    {
      title: '5',
      dataIndex: 'a5',
      key: 'a5',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v) => (v ? v : null),
    },
    {
      title: '6',
      dataIndex: 'a6',
      key: 'a6',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v) => (v ? v : null),
    },
    {
      title: '7',
      dataIndex: 'a7',
      key: 'a7',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v) => (v ? v : null),
    },
    {
      title: '8',
      dataIndex: 'a8',
      key: 'a8',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (v) => (v ? v : null),
    },
    {
      title: t('合計'),
      dataIndex: 'total',
      key: 'total',
      className: `w-20 h-6 text-center-f font-black ant-table-${genderClass}-test`,
      render: (_, r) => {
        return (
          (r?.a1 ?? 0) +
          (r?.a2 ?? 0) +
          (r?.a3 ?? 0) +
          (r?.a4 ?? 0) +
          (r?.a5 ?? 0) +
          (r?.a6 ?? 0) +
          (r?.a7 ?? 0) +
          (r?.a8 ?? 0)
        )
      },
    },
  ]

  let data =
    (isMale ? questionnaireSummary?.male : questionnaireSummary?.female) ?? {}
  data = Object.entries(data).map(([k, v]) => ({
    question: k.toUpperCase(),
    ...(v as Object),
  }))
  data = data.filter((_value, key) => {
    // Skip for aomori pref
    if (school?.attributes?.prefectureCode === 2) {
      if (
        (isElementarySchool && key >= 10 && key <= 16) || (!isElementarySchool && key >= 11 && key <= 17)
      ) return false
    }
    if (listQuestion[key] === 45) {
      return false
    }
    return key < listQuestion?.length
  })
  return (
    <>
      <div className="space-y-2">
        <div
          className={`h-6 flex justify-center items-center text-xs text-white font-black table-${genderClass}-test`}
        >
          {t('アンケート')}
          {t(isMale ? '（男子）' : '（女子）')}
        </div>

        <Row>
          <Col span={10}>
            <Table
              columns={questionColumns}
              dataSource={data}
              style={{ width: 500 }}
              rowKey="question"
              size="small"
              rowClassName={(_, idx) => {
                const _default = 'text-10px text-black font-bold'
                return `${_default} ${
                  idx % 2 !== 0
                    ? `ant-table-row-${rowColor}-light`
                    : `ant-table-row-${rowColor}-dark`
                }`
              }}
              pagination={false}
              loading={loading}
            />
          </Col>
          <Col span={13}>
            <BarChart
              width={550}
              height={450}
              layout="vertical"
              data={data}
              stackOffset="sign"
              barCategoryGap={8}
            >
              <XAxis
                type="number"
                tick={false}
                domain={[0, 'dataMax']}
                axisLine={false}
              />
              <YAxis
                dataKey="question"
                type="category"
                interval={0}
                tickLine={false}
                style={{ color: 'red' }}
              />
              <Bar barSize={16} dataKey="a1" stackId="stack" fill="#036EB8" />
              <Bar barSize={16} dataKey="a2" stackId="stack" fill="#F97A3C" />
              <Bar barSize={16} dataKey="a3" stackId="stack" fill="#808080" />
              <Bar barSize={16} dataKey="a4" stackId="stack" fill="#FFBC31" />
              <Bar barSize={16} dataKey="a5" stackId="stack" fill="#91C3E5" />
              <Bar barSize={16} dataKey="a6" stackId="stack" fill="#6AAB4E" />
              <Bar barSize={16} dataKey="a7" stackId="stack" fill="#B80303" />
              <Bar barSize={16} dataKey="a8" stackId="stack" fill="#EB99FF" />
              <Legend
                formatter={(value) => value.substring(1, 2)}
                verticalAlign="top"
                align="right"
              />
            </BarChart>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default QuestionnaireTable
